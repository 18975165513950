import "./App.css";
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import TextDisplay from "./components/TextDisplay.js";
import EnterText from "./components/EnterText.js";
import ConjVerify from "./components/ConjVerify.js";

function App() {
  const [article, setArticle] = useState([
    '<mark style="border-radius:0.2em;" data-illuminate="tooltip" class=\'positive\'><span data-illuminate="tooltiptext">I am stubborn<hr>I am pig-headed</span>I am firm</mark>, <mark style="border-radius:0.2em;" data-illuminate="tooltip" class=\'negative\'><span data-illuminate="tooltiptext">you are firm<hr>you are determined</span>you are obstinate</mark>, <mark style="border-radius:0.2em;" data-illuminate="tooltip" class=\'negative\'><span data-illuminate="tooltiptext">he is firm<hr>he is determined</span>he is a pig-headed fool</mark>.',
  ]);
  const [loading, setLoading] = useState(false);
  const [showBox, setShowBox] = useState(false);
  const [id, setId] = useState(null);
  const [data, setData] = useState(null);

  let headers = new Headers();

  headers.append("Content-Type", "application/json");
  //headers.append("Origin", "http://localhost:3000");
  //headers.append('Origin','https://russellconjugations.com');

  let params = new URLSearchParams(document.location.search);

  useEffect(() => {
    if(!params.get("RadBUJ0Yla")) {
      return;
    }

    const getParam = async () => {
      const response = await fetch(
        //'https://llkuy39n29.execute-api.us-east-1.amazonaws.com/prod/api/param',
        "http://localhost:5000/api/param",
        {
          method: "POST",
          headers,
          body: JSON.stringify({
            param: params.get("RadBUJ0Yla"),
          }),
        }
      );
      await response;

      if (response.status !== 200) {
        return;
      } else {
        setShowBox(true);
      }
    };

    getParam();
  }, []);

  const makeReq = async (text, token, event) => {
    try {
      const response = await fetch(
        //'https://llkuy39n29.execute-api.us-east-1.amazonaws.com/prod/api/getConj',
        "http://localhost:5000/api/getConj",
        {
          method: "POST",
          headers,
          body: JSON.stringify({
            str: text,
            token,
          }),
        }
      );

      const tempResponseData = await response;

      if (response.status !== 200) {
        return response.status;
      }

      const responseData = await tempResponseData.json();

      return responseData.conj;
    } catch (err) {
      console.log(err);
      return "An error occured";
    }
  };

  async function textSubmitHandler(text, token) {
    setLoading(true);

    var gptText = text
      .split(/[\r\n]+/)
      .map((p, i) => {
        return `p_${i}: ` + p;
      })
      .join("\n");

    var data = await makeReq(gptText, token);

    if (data === 422) {
      setLoading(false);
      setArticle([
        '<span style="color:red">Invalid captcha or other error</span>',
      ]);
      return;
    } else if (data === "429") {
      setLoading(false);
      setArticle([
        '<span style="color:red">You have exceeded one of the usage limits (5 requests in 5 minutes, or 50 requests in 24 hours).<br><br>This is a free tool for demonstration, but it costs money to maintain. Future projects with this technology will utilize more flexible business models.</span>',
      ]);
      return;
    }

    setData(data);
    setId(data.Text.S);
  }

  function textFormatter(data, gptText) {
    //console.log(data);
    //console.log(gptText);
    // Split paragraphs and add conjugations
    var elements = gptText.split("\n").map((paragraph, p_) => {
      //console.log(data[`p_${p_}`] + "\n" + `p_${p_}` + "\n" + data.p_0);

      if (!data[`p_${p_}`]) {
        return paragraph.replace(`p_${p_}:`, "");
      }
      var conjugations = data[`p_${p_}`];

      for (var i = 0; i < conjugations.length; ++i) {
        var regex = new RegExp(
          `(?<!>)${conjugations[i][0].replace(
            /[.*+?^${}()|[\]\\]/g,
            "\\$&"
          )}(?!<)`,
          "g"
        );

        paragraph = paragraph.replaceAll(
          regex,
          `<mark data-illuminate='tooltip' ${
            conjugations[i][1] === "positive" && !conjugations[i][2][0]
              ? "style='background-color:rgba(0,255,0,.5);border-radius:0.2em;'>" +
                `<span data-illuminate='tooltiptext'>Positive Sentiment` +
                `</span>`
              : conjugations[i][1] === "positive"
              ? "class='positive' style='border-radius:0.2em;'>" +
                `<span data-illuminate='tooltiptext'>${conjugations[i][2][0]}` +
                (conjugations[i][2][1] ? `<hr>${conjugations[i][2][1]}` : ``) +
                `</span>`
              : conjugations[i][1] === "negative" && !conjugations[i][2][0]
              ? "style='background-color:rgba(255,0,0,.5);border-radius:0.2em;'>" +
                `<span data-illuminate='tooltiptext'>Negative Sentiment` +
                `</span>`
              : conjugations[i][1] === "negative"
              ? "class='negative' style='border-radius:0.2em;'>" +
                `<span data-illuminate='tooltiptext'>${conjugations[i][2][0]}` +
                (conjugations[i][2][1] ? `<hr>${conjugations[i][2][1]}` : ``) +
                `</span>`
              : ""
          }${conjugations[i][0]}</mark>`
        );
      }

      return paragraph.replace(`p_${p_}:`, "");
    });

    setLoading(false);
    setArticle(elements);
  }

  return (
    <Router>
    {id && <Navigate to={`/conj/${id}`} replace />}
    <div className="main">
      <h1>Russell Conjugation Illuminator</h1>
      {showBox ? (
        <>
          <p>Enter text here to discover its invisible bias!</p>
          <EnterText onSubmit={textSubmitHandler} disable={loading} />
        </>
      ) : (
        <>
          <p>
            An automatic detector for intrinsic bias
            <br />
            Limited release!
            <br />
            <a href="https://forms.gle/Qm7xiti8kMx7QcQr7">Request Access</a>
          </p>
          <br />
          <hr />
          <div className="container">
            <p>Learn more:</p>
            <p>
              &emsp;
              <a href="https://en.wikipedia.org/wiki/Emotive_conjugation">
                en.wikipedia.org/wiki/Emotive_conjugation
              </a>
              <br />
              &emsp;
              <a href="https://www.edge.org/response-detail/27181">
                edge.org/response-detail/27181
              </a>
              <br />
              <br />
            </p>
          </div>
        </>
      )}
      <hr />
      <Routes>
        <Route path="/" element={<TextDisplay className="article" text={article} loading={loading} />} exact />
        <Route path="/conj/:conjId" element={<ConjVerify className="article" exists={id} data={data} text={article} onGet={textFormatter} loading={loading} />} exact />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>

      <hr />
      <footer className="footah">
        <form
          action="https://www.paypal.com/donate"
          method="post"
          target="_top"
        >
          <input type="hidden" name="business" value="RU74DK78GYG24" />
          <input type="hidden" name="no_recurring" value="0" />
          <input
            type="hidden"
            name="item_name"
            value="Help support operating costs and improvements!"
          />
          <input type="hidden" name="currency_code" value="USD" />
          <input
            type="image"
            src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif"
            border="0"
            name="submit"
            title="PayPal - The safer, easier way to pay online!"
            alt="Donate with PayPal button"
          />
          <img
            alt=""
            border="0"
            src="https://www.paypal.com/en_US/i/scr/pixel.gif"
            width="1"
            height="1"
          />
        </form>
        <h4>©Timothy McAllister, 2024. All Rights Reserved.</h4>
      </footer>
    </div>
    </Router>
  );
}

export default App;
