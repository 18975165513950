import { Container, Placeholder, Col, Row } from 'react-bootstrap';
import DOMPurify from 'dompurify';

const TextDisplay = props => {

    // Use placeholder for text while waiting for it. Also disable the button during this time.
    return (
        <Container id="bigContainer">
            {props.loading ?
            <>
                <Placeholder as="p" animation="glow">
                    <Placeholder xs={12} />
                </Placeholder>
                <Placeholder as="p" animation="glow">
                    <Placeholder xs={12} />
                </Placeholder>
                <Placeholder as="p" animation="glow">
                    <Placeholder xs={12} />
                </Placeholder>
            </>
            :
            <Row>
                <Col lg={1} xxs={0}/>
                <Col lg={10} xxs={12}>
                    {props.text.map((p, i) => {
                        return <p key={`p_${i}`} data-illuminate={`p_${i}`} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(p)}}></p>
                    })}
                </Col>
                <Col lg={1} xxs={0}/>
            </Row>
            }
        </Container>
    );
}

export default TextDisplay;